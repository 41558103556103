// i18next-extract-mark-ns-start terms
import * as React from "react"
import { graphql } from "gatsby"
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageSection from "../components/page-section"


const TermsOfServicePage = () => {

    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t("Regulaminy rejsów")} />
            <PageSection>
                <h1 className="pb-5"><Trans>Regulaminy rejsów</Trans></h1>
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col text-center"><Link to="/regulaminy/bezplatne" type="button" className="btn btn-secondary btn-lg">Rejsy bezpłatne</Link></div>
                        <div className="col text-center"><Link to="/regulaminy/platne" type="button" className="btn btn-secondary btn-lg">Rejsy płatne</Link></div>
                        <div className="col text-center"><Link to="/regulaminy/swieto" type="button" className="btn btn-secondary btn-lg">Święto Wisły 2023</Link></div>
                    </div>
                </div>
            </PageSection>
        </Layout>
    )

}

export default TermsOfServicePage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["terms", "navigation", "footbar"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;